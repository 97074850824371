const App = () => {
  return (
    <div className="">
      <div className="flex flex-col bg-mybg items-center justify-center min-h-screen">
        <img src="TransparentLogo.png" className="h-screen" alt="logo" />
      </div>

    </div>
  )
}

export default App
